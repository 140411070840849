import React, { useContext } from 'react';
import useSetState from '../../utils/useSetState';
import onSubmitHandler from './utils/onSubmitHandler';
import onChangeHandler from './utils/onChangeHandler';
import { UserContext } from '../../containers/UserContext/UserContext';
import { FeedbackContext } from '../context/FeedbackContext/FeedbackContext';
import { LangContext } from '../context/LangContext';
import styles from './Register.module.css';

const Register = ({ location }) => {
  const { translations } = useContext(LangContext);

  const [state, setState] = useSetState({
    family_invite: null,
    first_name: '',
    last_name: '',
    email: '',
    confirm_email: '',
    password: '',
    confirm_password: '',
    isLoading: false,
    invite_code: ''
  });

  const { setUserGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  return (
    <div className="page-contents">
      <div className="utility-page-wrap bg-gray-1">
        <div className={styles.adjustTop + ' container'}>
          <div className="content-width-medium align-center">
            <div className="form-heading account-form-heading">
              <h1 className="display-heading-2">
                {translations.registerTitle}
              </h1>
              <div className="large-text">{translations.registerSubtitle}</div>
            </div>
            <div className="form-block w-form">
              <form
                onSubmit={onSubmitHandler.bind(null, {
                  setState,
                  state,
                  setUserGlobalState,
                  setFeedbackState
                })}
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="form-grid-vertical"
              >
                <input
                  required={true}
                  value={state.first_name}
                  onChange={onChangeHandler.bind(null, setState, 'first_name')}
                  type="text"
                  className="form-input form-input-large form-input-dark w-input"
                  name="first_name"
                  id="first_name"
                  data-name="first_name"
                  placeholder={translations.firstNamePlace}
                  label={translations.firstNamePlace}
                />

                <input
                  required={true}
                  value={state.last_name}
                  onChange={onChangeHandler.bind(null, setState, 'last_name')}
                  type="text"
                  className="form-input form-input-large form-input-dark w-input"
                  name="last_name"
                  data-name="last_name"
                  id="first_name"
                  placeholder={translations.lastNamePlace}
                  label={translations.lastNamePlace}
                />

                <input
                  required={true}
                  value={state.email}
                  onChange={onChangeHandler.bind(null, setState, 'email')}
                  type="email"
                  className="form-input form-input-large form-input-dark w-input"
                  name="email"
                  data-name="Email"
                  id="email"
                  placeholder={translations.emailPlace}
                  label={translations.emailPlace}
                />

                <input
                  required={true}
                  value={state.confirm_email}
                  onChange={onChangeHandler.bind(
                    null,
                    setState,
                    'confirm_email'
                  )}
                  type="email"
                  className="form-input form-input-large form-input-dark w-input"
                  name="confirm_email"
                  data-name="confirm_email"
                  id="confirm_email"
                  placeholder={translations.confirmEmailPlace}
                  label={translations.confirmEmailPlace}
                />

                <input
                  required={true}
                  value={state.password}
                  onChange={onChangeHandler.bind(null, setState, 'password')}
                  type="password"
                  className="form-input form-input-large form-input-dark w-input"
                  name="Password"
                  data-name="Password"
                  id="Password"
                  placeholder={translations.passwordPlace}
                  label={translations.passwordPlace}
                />

                <input
                  required={true}
                  value={state.confirm_password}
                  onChange={onChangeHandler.bind(
                    null,
                    setState,
                    'confirm_password'
                  )}
                  type="password"
                  className="form-input form-input-large form-input-dark w-input"
                  name="confirm_password"
                  data-name="confirm_password"
                  id="confirm_password"
                  placeholder={translations.confirmPasswordPlace}
                  label={translations.confirmPasswordPlace}
                />

                <input
                  required={true}
                  value={state.invite_code}
                  onChange={onChangeHandler.bind(null, setState, 'invite_code')}
                  type="password"
                  className="form-input form-input-large form-input-dark w-input"
                  name="invite_code"
                  data-name="invite_code"
                  id="invite_code"
                  placeholder={translations.inviteCodePlace}
                  label={translations.inviteCodePlace}
                />

                <input
                  type="submit"
                  value={translations.submitBtnText}
                  data-wait="Please wait..."
                  className="button button-large bg-primary-4 w-button"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
