import axios from 'axios';
import { api_base_url } from '../../../data/client';
import { navigate } from 'gatsby';

const email_mismatch_message = "The emails provided don't match";
const password_mismatch_message = "The passwords provided don't match";
const no_name_message = 'Please provide a first name';
const no_password_message = 'Please provide a password';

const onSubmitHandler = async (
  { setState, state, setUserGlobalState, setFeedbackState },
  e
) => {
  try {
    e.preventDefault();

    // Validation

    if (!state.password) {
      setFeedbackState({
        isActive: true,
        message: no_password_message,
        isLoading: false,
        isError: true,
        setState
      });
    } else if (state.confirm_password != state.password) {
      setFeedbackState({
        isActive: true,
        isLoading: false,
        message: password_mismatch_message,
        isError: true,
        setState
      });
    } else if (state.email != state.confirm_email) {
      setFeedbackState({
        isActive: true,
        isLoading: false,
        message: email_mismatch_message,
        isError: true,
        setState
      });
    } else if (!state.first_name) {
      setFeedbackState({
        isActive: true,
        isLoading: false,
        message: no_name_message,
        isError: true
      });
    }

    //
    else {
      setFeedbackState({
        isActive: true,
        isError: false,
        isLoading: true
      });

      const res = await axios({
        url: api_base_url + '/users/auth/register',
        method: 'POST',
        headers: {
          'Content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify(state)
      });

      const user = res.data.user;

      if (!res.data.error) {
        setFeedbackState({
          isActive: true,
          message: 'Success',
          isError: false,
          isLoading: false
        });

        localStorage.setItem('s_t_kmp', res.data.token);

        setUserGlobalState(res.data);

        navigate('/dashboard');
      } else {
        setFeedbackState({
          isActive: true,
          isLoading: false,
          message: res.data.message,
          isError: true
        });
      }
    }
  } catch (err) {
    setFeedbackState({
      isActive: true,
      message: 'Unexpected Error, contact us please',
      isError: true,
      isLoading: false
    });
  }
};

export default onSubmitHandler;
