import React from 'react';
import Register from '../containers/Register/Register';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';

const RegisterPage = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <Register location={location} />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default RegisterPage;
